<template>
	<!-- 认证订单 -->
	<div class="cert_order">
		<div class="el-content mb12">
			<div class="mb12">
				<span class="f12">类型/状态：</span>
				<a-select v-model:value="search.cert_id" placeholder="认证类型" style="width: 200px;">
					<a-select-option value="">请选择认证类型</a-select-option>
					<a-select-option :value="v.cert_id" v-for="(v,i) in cert" :key="i">{{v.cert_name}}</a-select-option>
				</a-select>
				<a-select v-model:value="search.pay_status" placeholder="支付状态" style="width: 200px;margin-left: 12px;">
					<a-select-option value="">请选择支付状态</a-select-option>
					<a-select-option value="1">已支付</a-select-option>
					<a-select-option value="0">未支付</a-select-option>
				</a-select>
			</div>
			<div class="mb12">
				<span class="f12">订单编号：</span>
				<a-input v-model:value="search.order_sn" placeholder="订单编号" style="width: 400px;"></a-input>
			</div>
			<div class="mb12">
				<span class="f12">店 铺 I D ：</span>
				<a-input v-model:value="search.shop_id" placeholder="店铺id" style="width: 400px;"></a-input>
			</div>
			<div class="mb12">
				<span class="f12">交 易 号 ：</span>
				<a-input v-model:value="search.pay_sn" placeholder="支付交易号" style="width: 400px;"></a-input>
			</div>
			<a-button type="primary" style="margin-left: 60px;" @click="getCertOrder(1,info.limit)">查询</a-button>
		</div>
		<div class="el-content">
			<a-table row-key="order_id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'order_id'},
				{title:'订单编号',dataIndex:'order_sn'},
				{title:'订单总价',dataIndex:'amount'},
				{title:'支付方式',dataIndex:'pay_type',slots:{customRender:'pay_type'}},
				{title:'支付状态',dataIndex:'pay_status',slots:{customRender:'pay_status'}},
				{title:'支付交易号',dataIndex:'pay_sn'},
				{title:'创建时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #pay_type="{record}">
					<span>{{$util.payMethod(record.pay_method)}}</span>
				</template>
				<template #pay_status="{record}">
					<a-tag :color="record.pay_status == 1 ? '#00CC66':'#999'">{{record.pay_status == 1 ?'已支付':'未支付'}}</a-tag>
				</template>
				<template #action="{record}">
					<com-pay-result :id="record.order_id" :type="5"></com-pay-result>
				</template>
			</a-table>
		</div>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getCertOrder(info.page,e)}"
				@change="(e)=>{getCertOrder(e,info.limit)}"
			/>
		</div>
	</div>
	
</template>

<script>
import comPayResult from "@/components/miniapp/com-pay-result.vue";
import { reactive, toRefs } from 'vue';
import shopModel from '@/api/addons/shop';
export default{
	components:{
		comPayResult
	},
	setup(){
		const _d = reactive({
			cert:[],
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			search:{
				cert_id:'',
				shop_id:'',
				order_sn:'',
				pay_sn:'',
				pay_status:''
			},
		})
		getCertOrder(1,_d.info.limit)
		shopModel.getShopCert(1,999,res=>_d.cert = res.list)
		function getCertOrder(page,limit){
			shopModel.getShopCertOrder(page,limit,_d.search,res=>_d.info = {limit,...res})
		}
		return{
			...toRefs(_d),
			getCertOrder
		}
	}
}
</script>

<style>
</style>
